var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        { attrs: { bg: "", fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: {
                    persistent: "",
                    "max-width": _vm.info_packet.max_width
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { color: "primary", dark: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Open Dialog")]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dialog,
                    callback: function($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "text-h5" }, [
                        _vm._v(" " + _vm._s(_vm.info_packet.card_heading))
                      ]),
                      _c(
                        "v-stepper",
                        {
                          model: {
                            value: _vm.e1,
                            callback: function($$v) {
                              _vm.e1 = $$v
                            },
                            expression: "e1"
                          }
                        },
                        [
                          _c(
                            "v-stepper-header",
                            _vm._l(_vm.info_packet.steps, function(item) {
                              return _c(
                                "v-stepper-step",
                                {
                                  key: item.id,
                                  attrs: {
                                    complete: _vm.e1 > item.id,
                                    step: item.id
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.step_name) + " ")]
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-stepper-items",
                            _vm._l(_vm.info_packet.steps, function(item) {
                              return _c(
                                "v-stepper-content",
                                { key: item.id, attrs: { step: item.id } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-12",
                                      attrs: {
                                        color: "grey lighten-3",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                _vm._l(item.text, function(
                                                  text_item
                                                ) {
                                                  return _c(
                                                    "ul",
                                                    { key: text_item.id },
                                                    [
                                                      _c("li", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(text_item) +
                                                            " "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }),
                                                0
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-img", {
                                                    attrs: { src: item.image }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.info_packet.steps.length ===
                                          _vm.e1
                                            ? (_vm.e1 = 1)
                                            : (_vm.e1 = item.id + 1)
                                        }
                                      }
                                    },
                                    [_vm._v(" Back ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.info_packet.steps.length ===
                                          _vm.e1
                                            ? (_vm.e1 = 1)
                                            : (_vm.e1 = item.id + 1)
                                        }
                                      }
                                    },
                                    [_vm._v(" Continue ")]
                                  ),
                                  _vm.e1 == 4
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.dialog = false
                                            }
                                          }
                                        },
                                        [_vm._v(" Close")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }