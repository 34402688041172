import { render, staticRenderFns } from "./trade-sars-national.vue?vue&type=template&id=0bf7fc9f&scoped=true&"
import script from "./trade-sars-national.vue?vue&type=script&lang=js&"
export * from "./trade-sars-national.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf7fc9f",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCard,VCardTitle,VCol,VContainer,VContent,VDialog,VImg,VRow,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0bf7fc9f')) {
      api.createRecord('0bf7fc9f', component.options)
    } else {
      api.reload('0bf7fc9f', component.options)
    }
    module.hot.accept("./trade-sars-national.vue?vue&type=template&id=0bf7fc9f&scoped=true&", function () {
      api.rerender('0bf7fc9f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-sars-national/trade-sars-national.vue"
export default component.exports